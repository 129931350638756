<template>
    <div>
        <template v-if="type=='users'">
            <div class="users">
                <Col span="6" class="mian">
                    <span>电量：{{userData.electric/10000}}度</span>
                </Col>
                <Col span="6" class="mian">
                    <span>消费：{{userData.consume/100}}元</span>
                </Col>
                <Col span="6" class="mian">
                    <span>频率：{{userData.useNum}}次</span>
                </Col>
            </div>
        </template>
        <template v-else>
            <Row v-for="(item,index) in list" :key="index">
                <Col span="8" class="mian">
                    <span>{{ item.num }}枪</span>
                </Col>
                <Col span="8" class="mian">
                    <span>状态: </span>
                    <span>{{ state(item.state) }}</span>
                </Col>
            </Row>
        </template>
    </div>
</template>
<script>
    export default {
        props: {
            type: String,
            row: Array,
            userData: Object
        },
        data(){
            return{
                list: this.row
            }
        },
        methods: {
            state(num){
                if(num==0){
                    return '空闲'
                }else if(num == 1){
                    return '充电中'
                }else if(num == 2){
                    return '离线'
                }else if(num == 3){
                    return '故障' 
                }
            }
        }
    }
</script>
<style scoped>
    .users{
        display: flex;
        justify-content: center
    }
    .mian{
        padding: 5px 0;
        margin-left: 16px;
    }
    .ivu-row{
        justify-content: space-around;
    }
</style>