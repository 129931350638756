<template>
    <div id="container"></div>
</template>

<script>
    import { mapKey, center} from '@/libs/apiBIgScreen'
    import AMapLoader from '@amap/amap-jsapi-loader'
    import { getMap} from '@/libs/apiBIgScreen'
    export default {
        name: 'index',
        mounted(){
            this.$nextTick(()=>{
                setTimeout(()=>{
                    this.init()
                },500)
            })
        },
        data(){
            return {
                sitePosition: []
            }
        },
        methods: {
            init(){
                AMapLoader.load({
                    key: mapKey,              // 申请好的Web端开发者Key，首次调用 load 时必填
                    version: "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                    plugins: ['AMap.DistrictSearch','AMap.ControlBar','AMap.GeoJSON'],   // 需要使用的的插件列表
                    Loca:{                // 是否加载 Loca， 缺省不加载
                        "version": '2.0'  // Loca 版本，缺省 1.3.2
                    },
                    AMapUI: {
                        version: '1.1',
                        plugins:['overlay/SimpleMarker']
                    }
                }).then((AMap)=>{
                    getMap().then((res)=>{
                        let data = res.data
                        if(data.code === 0){
                            data.data.forEach((item)=>{
                                this.sitePosition.push({
                                    "type": "Feature",
                                    "properties": {
                                        "name": item.siteName.substr(0,6) + "...",
                                        "num": item.chargePoints.length
                                    },
                                    "geometry":{
                                        "type":"Point",
                                        "coordinates":[
                                            item.stationLng,
                                            item.stationLat
                                        ]
                                    }
                                })
                            })
                        }
                    })
                    let features = this.sitePosition
                    let siteinfo = {
                        "type":"FeatureCollection",
                        features
                    }
                    const district = new AMap.DistrictSearch({subdistrict:0,extensions:'all',level:'province'});
                    district.search('四川省',(status, result)=>{
                        const bounds = result.districtList[0].boundaries
                        const mask = []
                        for (let i=0;i<bounds.length;i++){
                            mask.push([bounds[i]])
                        }
                        const map = new AMap.Map("container",{  // 设置地图容器id
                            mask: mask, // 为Map实例制定掩模的路径,各图层将值显示路径范围内图像,3D模式下有效
                            zoom: center.zoom, // 设置当前显示级别
                            expandZoomRange: true, // 开启显示范围设置
                            zooms: [center.zoom, 20], //最小显示级别为6，最大显示级别为20
                            center: [center.lng,center.lat], // 设置地图中心点位置
                            viewMode: "3D",    // 特别注意,设置为3D则其他地区不显示
                            zoomEnable: true, // 是否可以缩放地图
                            resizeEnable: true, //是否监控地图容器尺寸变化
                            buildingAnimation: true,//楼块出现是否带动画
                            pitch: 40, //俯视角度
                            mapStyle: "amap://styles/656cc940364928110fef6b13a20d19f5"
                        });
                        map.addControl(new AMap.ControlBar({ //转盘
                            showZoomBar:false,
                            showControlButton:true,
                            position:{
                                right:'10px',
                                top:'10px'
                            }
                        }))
                        
                        // 添加描边
                        for (let i=0;i<bounds.length; i++) {
                            const polyline = new AMap.Polyline({
                                path: bounds[i], // polyline 路径，支持 lineString 和 MultiLineString
                                strokeColor: '#00D3FC', // 线条颜色，使用16进制颜色代码赋值。默认值为#00D3FC
                                strokeWeight: 2, // 轮廓线宽度,默认为:2
                                strokeOpacity: 0.5 //轮廓线透明度,默认为0.9
                            })
                            polyline.setMap(map);
                        }
                        //行政区域加载
                        let loca = new Loca.Container({
                            map
                        });
                        
                        let geo = new Loca.GeoJSONSource({
                            data: siteinfo
                        });
                        let geos = new Loca.GeoJSONSource({
                            url: 'https://geo.datav.aliyun.com/areas_v3/bound/510000_full.json',
                        });
                        // 呼吸点
                        // let breathRed = new Loca.ScatterLayer({
                        //     loca,
                        //     zIndex: 100,
                        //     opacity: 1,
                        //     visible: true,
                        //     zooms: [2, 22],
                        // });
                        // breathRed.setSource(geo, {
                        //     unit: 'px',
                        //     size: [100, 100],
                        //     texture: 'https://a.amap.com/Loca/static/loca-v2/demos/images/breath_red.png',
                        //     borderWidth: 0,
                        //     animate: true,
                        //     duration: 1000,
                        // });
                        // loca.add(breathRed);
                        // loca.animate.start();

                        //区域面图层
                        let pl = new Loca.PolygonLayer({
                            zIndex: 120,
                            opacity: 0.8,
                            shininess: 10,
                            hasSide: true,
                        });

                        pl.setSource(geos);
                        pl.setStyle({
                            topColor: 'rgba(0,0,0,0)',
                            sideTopColor: '#dc3912',
                            sideBottomColor: '#ff9900',
                            height: 20000,
                            altitude: 0,
                        });
                        loca.add(pl);

                        //添加充电桩图层
                        let labelsLayer = (window.labelsLayer = new Loca.LabelsLayer({
                            zooms: [5, 20]
                        }));
                        labelsLayer.setSource(geo);
                        labelsLayer.setStyle({
                            icon: {
                                type: 'image',
                                image: 'https://a.amap.com/Loca/static/loca-v2/demos/mock_data/charging_pile.png',
                                size: [48, 75],
                                anchor: 'center',
                            },
                            text: {
                                // 每项配置都可使用回调函数来动态配置
                                content: (index, feat) => {
                                    return feat.properties.name;
                                },
                                style: {
                                    fontSize: 12,
                                    fontWeight: 'normal',
                                    fillColor: '#5CDE8E',
                                    strokeColor: '#000',
                                    strokeWidth: 2,
                                },
                                direction: 'bottom',
                            },
                            extData: (index, feat) => {
                                return feat.properties;
                            }
                        });
                        loca.add(labelsLayer);
                        labelsLayer.on('complete', () => {
                            let normalMarker = new AMap.Marker({
                                offset: [70, -15],
                            });
                            let labelMarkers = labelsLayer.getLabelsLayer().getAllOverlays();
                            for (let marker of labelMarkers) {
                                marker.on('mouseover', (e) => {
                                    let position = e.data.data && e.data.data.position;

                                    if (position) {
                                        normalMarker.setContent(
                                            '<div>数量：' + marker.getExtData().num + '个' + '</div>',
                                        );
                                        normalMarker.setPosition(position);
                                        map.add(normalMarker);
                                    }
                                });
                                marker.on('mouseout', () => {
                                    map.remove(normalMarker);
                                })
                            }
                        })
                        
                        // //添加地图浏览
                        // let colors = [
                        //     "#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477", "#66aa00",
                        //     "#b82e2e", "#316395", "#994499", "#22aa99", "#aaaa11", "#6633cc", "#e67300", "#8b0707",
                        //     "#651067", "#329262", "#5574a6", "#3b3eac"
                        // ];
                        AMapUI.load(['ui/geo/DistrictExplorer', 'lib/$'], function(DistrictExplorer, $) {

                            //创建一个实例
                            let districtExplorer = window.districtExplorer = new DistrictExplorer({
                                eventSupport: true, //打开事件支持
                                map: map
                            });

                            //当前聚焦的区域
                            let currentAreaNode = null;

                            //鼠标hover提示内容
                            let $tipMarkerContent = $('<div class="tipMarker top"></div>');

                            let tipMarker = new AMap.Marker({
                                content: $tipMarkerContent.get(0),
                                offset: new AMap.Pixel(0, 0),
                                bubble: true
                            });

                            //根据Hover状态设置相关样式
                            function toggleHoverFeature(feature, isHover, position) {

                                tipMarker.setMap(isHover ? map : null);

                                if (!feature) {
                                    return;
                                }

                                let props = feature.properties;

                                if (isHover) {

                                    //更新提示内容
                                    $tipMarkerContent.html(props.name); //无行政编吗
                                    // $tipMarkerContent.html(props.adcode + ': ' + props.name); //有行政编码
                                    //更新位置
                                    tipMarker.setPosition(position || props.center);
                                }

                                $('#area-tree').find('h2[data-adcode="' + props.adcode + '"]').toggleClass('hover', isHover);

                                //更新相关多边形的样式
                                let polys = districtExplorer.findFeaturePolygonsByAdcode(props.adcode);
                                for (let i = 0, len = polys.length; i < len; i++) {
                                    polys[i].setOptions({
                                        fillOpacity: isHover ? 0.5 : 0.2
                                    });
                                }
                            }

                            //监听feature的hover事件
                            districtExplorer.on('featureMouseout featureMouseover', function(e, feature) {
                                toggleHoverFeature(feature, e.type === 'featureMouseover',
                                    e.originalEvent ? e.originalEvent.lnglat : null);
                            });

                            //监听鼠标在feature上滑动
                            districtExplorer.on('featureMousemove', function(e, feature) {
                                //更新提示位置
                                tipMarker.setPosition(e.originalEvent.lnglat);
                            });

                            //feature被点击
                            districtExplorer.on('featureClick', function(e, feature) {

                                let props = feature.properties;
                                //如果存在子节点
                                // if (props.childrenNum > 0) {
                                    //切换聚焦区域
                                    switch2AreaNode(props.adcode);
                                // }
                            });

                            //外部区域被点击
                            districtExplorer.on('outsideClick', function(e) {
                                
                                districtExplorer.locatePosition(e.originalEvent.lnglat, function(error, routeFeatures) {

                                    if (routeFeatures && routeFeatures.length > 1) {
                                        //切换到省级区域
                                        // switch2AreaNode(routeFeatures[1].properties.adcode);
                                        switch2AreaNode(510000);
                                    } else {
                                        //切换到四川
                                        switch2AreaNode(510000);
                                    }

                                }, {
                                    levelLimit: 2
                                });
                            });

                            //绘制区域面板的节点
                            function renderAreaPanelNode(ele, props, color) {

                                let $box = $('<li/>').addClass('lv_' + props.level);

                                let $h2 = $('<h2/>').addClass('lv_' + props.level).attr({
                                    'data-adcode': props.adcode,
                                    'data-level': props.level,
                                    'data-children-num': props.childrenNum || void(0),
                                    'data-center': props.center.join(',')
                                }).html(props.name).appendTo($box);

                                if (color) {
                                    $h2.css('borderColor', color);
                                }

                                //如果存在子节点
                                if (props.childrenNum > 0) {

                                    //显示隐藏
                                    $('<div class="showHideBtn"></div>').appendTo($box);

                                    //子区域列表
                                    $('<ul/>').addClass('sublist lv_' + props.level).appendTo($box);

                                    $('<div class="clear"></div>').appendTo($box);

                                    if (props.level !== 'country') {
                                        $box.addClass('hide-sub');
                                    }
                                }

                                $box.appendTo(ele);
                            }

                            //填充某个节点的子区域列表
                            function renderAreaPanel(areaNode) {

                                let props = areaNode.getProps();

                                let $subBox = $('#area-tree').find('h2[data-adcode="' + props.adcode + '"]').siblings('ul.sublist');

                                if (!$subBox.length && props.childrenNum) {
                                    //父节点不存在，先创建
                                    renderAreaPanelNode($('#area-tree'), props);
                                    $subBox = $('#area-tree').find('ul.sublist');
                                }
                                if ($subBox.attr('data-loaded') === 'rendered') {
                                    return;
                                }

                                $subBox.attr('data-loaded', 'rendered');

                                // let subFeatures = areaNode.getSubFeatures();

                                // //填充子区域
                                // for (let i = 0, len = subFeatures.length; i < len; i++) {
                                //     renderAreaPanelNode($subBox, areaNode.getPropsOfFeature(subFeatures[i]), colors[i % colors.length]);
                                // }
                            }

                            //绘制某个区域的边界
                            function renderAreaPolygons(areaNode) {
                                let mybounds = new AMap.Bounds([center.lng,center.lat], [center.lng,center.lat]);
                                if(areaNode.adcode == 510000){
                                    //更新地图视野
                                    map.setBounds(mybounds);
                                    map.setZoom(center.zoom); //设置地图层级
                                }else{
                                    //更新地图视野
                                    map.setBounds(areaNode.getBounds());
                                }
                                //清除已有的绘制内容
                                districtExplorer.clearFeaturePolygons();

                                //绘制子区域
                                districtExplorer.renderSubFeatures(areaNode, function(feature, i) {

                                    // let fillColor = colors[i % colors.length];
                                    // let strokeColor = colors[colors.length - 1 - i % colors.length];

                                    return {
                                        cursor: 'default',
                                        bubble: true,
                                        // strokeColor: strokeColor, //线颜色
                                        strokeOpacity: 0.5, //线透明度
                                        strokeWeight: 2, //线宽
                                        // fillColor: fillColor, //填充色
                                        fillOpacity: 0.35, //填充透明度
                                    };
                                });

                                //绘制父区域
                                districtExplorer.renderParentFeature(areaNode, {
                                    cursor: 'default',
                                    bubble: true,
                                    // strokeColor: 'black', //线颜色
                                    strokeOpacity: 0.5, //线透明度
                                    strokeWeight: 2, //线宽
                                    // fillColor: areaNode.getSubFeatures().length ? null : colors[0], //填充色
                                    fillOpacity: 0.35, //填充透明度
                                });
                            }

                            //切换区域后刷新显示内容
                            function refreshAreaNode(areaNode) {

                                districtExplorer.setHoverFeature(null);

                                renderAreaPolygons(areaNode);

                                //更新选中节点的class
                                let $nodeEles = $('#area-tree').find('h2');

                                $nodeEles.removeClass('selected');

                                let $selectedNode = $nodeEles.filter('h2[data-adcode=' + areaNode.getAdcode() + ']').addClass('selected');

                                //展开下层节点
                                $selectedNode.closest('li').removeClass('hide-sub');

                                //折叠下层的子节点
                                $selectedNode.siblings('ul.sublist').children().addClass('hide-sub');
                            }

                            //切换区域
                            function switch2AreaNode(adcode, callback) {

                                if (currentAreaNode && ('' + currentAreaNode.getAdcode() === '' + adcode)) {
                                    return;
                                }

                                loadAreaNode(adcode, function(error, areaNode) {

                                    if (error) {

                                        if (callback) {
                                            callback(error);
                                        }

                                        return;
                                    }

                                    currentAreaNode = window.currentAreaNode = areaNode;
                                    //设置当前使用的定位用节点
                                    districtExplorer.setAreaNodesForLocating([currentAreaNode]);

                                    refreshAreaNode(areaNode);

                                    if (callback) {
                                        callback(null, areaNode);
                                    }
                                });
                            }

                            //加载区域
                            function loadAreaNode(adcode, callback) {

                                districtExplorer.loadAreaNode(adcode, function(error, areaNode) {

                                    if (error) {

                                        if (callback) {
                                            callback(error);
                                        }

                                        console.error(error);

                                        return;
                                    }

                                    renderAreaPanel(areaNode);

                                    if (callback) {
                                        callback(null, areaNode);
                                    }
                                });
                            }

                            $('#area-tree').on('mouseenter mouseleave', 'h2[data-adcode]', function(e) {

                                if (e.type === 'mouseleave') {
                                    districtExplorer.setHoverFeature(null);
                                    return;
                                }

                                let adcode = $(this).attr('data-adcode');

                                districtExplorer.setHoverFeature(currentAreaNode.getSubFeatureByAdcode(adcode));
                            });


                            $('#area-tree').on('click', 'h2', function() {
                                let adcode = $(this).attr('data-adcode');
                                switch2AreaNode(adcode);
                            });

                            $('#area-tree').on('click', '.showHideBtn', function() {

                                let $li = $(this).closest('li');

                                $li.toggleClass('hide-sub');

                                if (!$li.hasClass('hide-sub')) {

                                    //子节点列表被展开
                                    let $subList = $li.children('ul.sublist');

                                    //尚未加载
                                    if (!$subList.attr('data-loaded')) {

                                        $subList.attr('data-loaded', 'loading');

                                        $li.addClass('loading');

                                        //加载
                                        loadAreaNode($li.children('h2').attr('data-adcode'), function() {

                                            $li.removeClass('loading');
                                        });
                                    }
                                }
                            });

                            //四川
                            switch2AreaNode(510000);
                        });
                    })
                })
            }
        }
    }
</script>

<style lang="less" scoped>

</style>