<template>
    <div>
        <div ref="userana" style="width: 100%;height: 155px;"></div>
        <Edialog :show="show" :info="info" @closeModal="closeModal"/>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    import { UserAnalysis} from '@/libs/apiBIgScreen'
    import Edialog from '../../modal'
    export default {
        components: {
            Edialog
        },
        name: 'index',
        mounted(){
            this.$nextTick(()=>{
                setTimeout(()=>{
                    this.init()
                },500)
            })
            this.time = setInterval(()=>{
                this.init()
                clearInterval(this.tiemSite)
                this.tiemSite = null
            },30000)
        },
        beforeDestroy(){
            clearInterval(this.time)
            this.time = null
        },
        data(){
            return {
                show: false,
                info: {}
            }
        },
        methods: {
            closeModal(){
               this.show = false 
            },
            init(){
                UserAnalysis({
                    topNum: 10
                }).then((res)=>{
                    let data = res.data
                    if(data.code === 0){
                        let electricQuantityRanking = []
                        let consumeRanking = []
                        let sessionRanking = []
                        data.data.electricQuantityRanking.forEach((item)=>{
                            electricQuantityRanking.push({
                                value: item.countNum/10000,
                                name: item.userName,
                                userId: item.userId
                            })
                        })
                        data.data.consumeRanking.forEach((item)=>{
                            consumeRanking.push({
                                value: item.countNum/100,
                                name: item.userName,
                                userId: item.userId
                            })
                        })
                        data.data.sessionRanking.forEach((item)=>{
                            sessionRanking.push({
                                value: item.countNum*10,
                                name: item.userName,
                                userId: item.userId
                            })
                        })

                        if (this.$refs.userana == null) {
                            return
                        }
                        let myChart = echarts.getInstanceByDom(this.$refs.userana);
                        if (myChart == null) { // 如果不存在，就进行初始化。
                            myChart = echarts.init(this.$refs.userana)
                        }
                        window.addEventListener("resize", () => {
                            myChart.resize()
                        })
                        myChart.on('click', (params) => {
                            this.show = true
                            this.info = Object.assign({},{showType:'expand',modalType:'users'},params)
                        })
                        let option = {
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: { // 坐标轴指示器，坐标轴触发有效
                                    type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                                },
                                formatter: (params)=>{
                                    return `
                                    ${params[0].data.name?params[0].data.name:'无名'}  <br/>
                                    ${params[0].marker}
                                    ${params[0].seriesName} :
                                    ${params[0].value.toLocaleString()}度 <br/>
                                    ${params[1].data.name?params[1].data.name:'无名'}  <br/>
                                    ${params[1].marker}
                                    ${params[1].seriesName} :
                                    ${(params[1].value).toLocaleString()}元 <br/>
                                    ${params[2].data.name?params[2].data.name:'无名'}  <br/>
                                    ${params[2].marker}
                                    ${params[2].seriesName} :
                                    ${(params[2].value/10).toLocaleString()}次`;
                                }
                            },
                            grid: {
                                left: '2',
                                right: '4',
                                bottom: '0',
                                top: '10',
                                containLabel: true
                            },
                            legend: {
                                selectedMode: false,
                                right: 10,
                                top: 0,
                                textStyle: {
                                    color: "#fff"
                                },
                                itemWidth: 13,
                                itemHeight: 10,
                                itemGap: 35
                            },
                            xAxis: {
                                type: 'category',
                                data: ['1','2','3','4','5','6','7','8','9','10'],
                                axisLine: {
                                    lineStyle: {
                                    color: '#fff'
                                    }
                                },
                                axisLabel: {
                                    textStyle: {
                                    fontFamily: 'Microsoft YaHei'
                                    }
                                }
                            },

                            yAxis: {
                                type: 'value',
                                axisLine: {
                                    show: false,
                                    lineStyle: {
                                    color: '#fff'
                                    }
                                },
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                    color: 'rgba(255,255,255,0.3)'
                                    }
                                },
                                axisLabel: {
                                    show: false
                                }
                            },
                            series: [
                                {
                                    name: '电量',
                                    type: 'bar',
                                    barWidth: '15%',
                                    itemStyle: {
                                        normal: {
                                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                                offset: 0,
                                                color: '#fccb05'
                                            }, {
                                                offset: 1,
                                                color: '#f5804d'
                                            }]),
                                            borderRadius: 12,
                                        },
                                    },
                                    data: electricQuantityRanking
                                },
                                {
                                    name: '消费',
                                    type: 'bar',
                                    barWidth: '15%',
                                    itemStyle: {
                                        normal: {
                                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                                offset: 0,
                                                color: '#8bd46e'
                                            }, {
                                                offset: 1,
                                                color: '#09bcb7'
                                            }]),
                                            borderRadius: 11,
                                        }

                                    },
                                    data: consumeRanking
                                },
                                {
                                    name: '频率',
                                    type: 'bar',
                                    barWidth: '15%',
                                    itemStyle: {
                                        normal: {
                                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                                offset: 0,
                                                color: '#248ff7'
                                            }, {
                                                offset: 1,
                                                color: '#6851f1'
                                            }]),
                                            borderRadius: 11,
                                        }
                                    },
                                    data: sessionRanking
                                }
                            ]
                        }
                        myChart.setOption(option)
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>

</style>