<template>
    <div class="content" :style="{minHeight: height + 'px'}">
        <!-- <dv-full-screen-container style="overflow-y: auto;overflow-x: hidden;height: 100%;"> -->
            <!-- 头部 -->
            <div class="main-box box-top">
                <div style="flex: 0 1 30%;">
                    <dv-decoration-10 class="decoration-10"/>
                    <p class="time">{{nowDate}}</p>
                </div>
                <div style="display: flex;; flex: 0 1 40%;">
                    <dv-decoration-8 class="decoration-8"/>
                    <div class="text-box">
                      满电行新能源充电管理系统
                        <dv-decoration-6 class="decoration-6"/>
                    </div>
                    <dv-decoration-8 class="decoration-8" :reverse="true"/>
                </div>
                <div style="flex: 0 1 30%;">
                    <dv-decoration-10 class="decoration-10-re"/>
                    <div class="rigth-main">
                        <p class="weather">今天天气：{{nowWeather.weather}} 温度：{{nowWeather.temperature_float}}°C 风向：{{nowWeather.winddirection}} 风力：{{nowWeather.windpower}}级 湿度：{{nowWeather.humidity_float}}%rh</p>
                        <span class="icon" @click="toLogout"><Icon type="md-exit" /></span>
                    </div>
                </div>
            </div>
            <!-- 上 -->
            <div class="main-box">
                <div class="border-left">
                    <div class="border-main">
                        <dv-border-box-1 class="site-pile">
                            <div class="site-content">
                                <div class="border-title">充电站电量排行</div>
                                <SiteEleRank/>
                            </div>
                        </dv-border-box-1>
                    </div>
                    <div>
                        <dv-border-box-8 class="order-list" reverse>
                            <div class="order-item">
                                <div class="border-title">充电桩数量排行</div>
                                <ElePilenum/>
                            </div>
                        </dv-border-box-8>
                    </div>
                    <dv-border-box-9 class="middle-main">
                        <div class="user-num">用户统计</div>
                        <div>
                            <UserInfo @userNumber="userNum"/>
                            <userCurve/>
                        </div>
                    </dv-border-box-9>
                </div>
                <dv-border-box-12 class="border-center">
                    <div class="statistics">
                        <dv-border-box-12 class="details-item">
                            <div class="details-list">
                                <i class="iconfont icon-dianzhanxinxi iconsize" title="电站" @click="getSite('site')"></i>
                                <div class="details-content">
                                    <countTo class="details-data" :startVal=0 :endVal="siteNum" :duration=3000 separator=","/>
                                    <span> 个</span>
                                </div>
                            </div>
                        </dv-border-box-12>
                        <dv-border-box-12 class="details-item">
                            <div class="details-list">
                                <i class="iconfont icon-chongdianzhuang iconsize" title="电桩" @click="getSite('pile')"></i>
                                <div class="details-content">
                                    <countTo class="details-data" :startVal=0 :endVal="chargingNum" :duration=3000 separator=","/>
                                    <span> 个</span>
                                </div>
                            </div>
                        </dv-border-box-12>
                        <dv-border-box-12 class="details-item">
                            <div class="details-list">
                                <i class="iconfont icon-dianliang1 iconsize" title="电量" @click="getSite('ele')"></i>
                                <div class="details-content">
                                    <countTo class="details-data" :startVal=0 :endVal="electricNum/10000" :duration=3000 separator=","/>
                                    <span> 度</span>
                                </div>
                            </div>
                        </dv-border-box-12>
                        <dv-border-box-12 class="details-item">
                            <div class="details-list">
                                <i class="iconfont icon-renminbi iconsize" title="收入" @click="getSite('money')"></i>
                                <div class="details-content">
                                    <countTo class="details-data" :options="{decimalPlaces:2}" :startVal=0 :endVal="moneyNum" :duration=3000 separator=","/>
                                    <span> 元</span>
                                </div>
                            </div>
                        </dv-border-box-12>
                    </div>
                    <!-- 地图 -->
                    <div class="map-container">
                        <EleMap/>
                    </div>
                </dv-border-box-12>
                <div class="border-right">
                    <dv-border-box-8 class="frequency">
                        <div class="charg-title">充电枪状态</div>
                        <ChargeState/>
                    </dv-border-box-8>
                    <dv-border-box-10 class="elecurve">
                        <div class="ele-title">订单状态统计</div>
                        <OrderState/>
                    </dv-border-box-10>
                    <dv-border-box-13 class="middle-main">
                        <div class="site-content">
                            <div class="operator-title">充电用户分析</div>
                            <UserAna/>
                        </div>
                    </dv-border-box-13>
                </div>
            </div>
            <!-- 下 -->
            <div class="main-box">
                <dv-border-box-8 class="down-main">
                    <div class="today-title">日收入曲线</div>
                    <DayMoney/>
                </dv-border-box-8>
                <dv-border-box-8 reverse class="down-main">
                    <div class="today-title">时电量曲线</div>
                    <Todayele/>
                </dv-border-box-8>
                <dv-border-box-8 reverse class="down-main">
                    <div class="today-title">日电量曲线</div>
                    <MoElePile/>
                </dv-border-box-8>
                <dv-border-box-8  class="down-main">
                    <div class="today-title">月电量曲线</div>
                    <YeElePile/>
                </dv-border-box-8>
            </div>
            <div class="main-box">
                <div class="footer">
                    <dv-border-box-13 class="footer-box">
                        <span class="title">累计充电时长</span>
                        <span class="data">{{chargeTime}}万小时</span>
                    </dv-border-box-13>
                    <dv-border-box-13 class="footer-box">
                        <span class="title">累计充电次数</span>
                        <span class="data">{{chargeNumber}}万次</span>
                    </dv-border-box-13>
                    <dv-border-box-13 class="footer-box">
                        <span class="title">累计订单数量</span>
                        <span class="data">{{orderNumber}}万个</span>
                    </dv-border-box-13>
                    <dv-border-box-13 class="footer-box">
                        <span class="title">当日充电量</span>
                        <span class="data">{{dayChargeAmount}}万度</span>
                    </dv-border-box-13>
                    <dv-border-box-13 class="footer-box">
                        <span class="title">当日充电次数</span>
                        <span class="data">{{dayChargeNumber}}次</span>
                    </dv-border-box-13>
                    <dv-border-box-13 class="footer-box">
                        <span class="title">当日充电时长</span>
                        <span class="data">{{dayChargeTime}}小时</span>
                    </dv-border-box-13>
                    <dv-border-box-13 class="footer-box">
                        <span class="title">当日充电费用</span>
                        <span class="data">{{dayChargePrice}}元</span>
                    </dv-border-box-13>
                    <dv-border-box-13 class="footer-box">
                        <span class="title">当日订单数量</span>
                        <span class="data">{{dayOrderNumber}}个</span>
                    </dv-border-box-13>
                    <dv-border-box-13 class="footer-box">
                        <span class="title">用户数量</span>
                        <span class="data">{{userNumber}}个</span>
                    </dv-border-box-13>
                </div>
            </div>
            <Edialog :show="show" :type="homeType" @closeModal="closeModal"/>
        <!-- </dv-full-screen-container> -->
    </div>
</template>

<script>
    import axios from 'axios'
    import dayjs from 'dayjs'
    import countTo from 'vue-countup-v2'
    import screenFull from 'screenfull';
    import {GetSiteChargingMemKwhTotle, SafeJsCode, AMapWebServiceKey } from '@/libs/apiBIgScreen'
    import SiteEleRank from './echarts/SiteEleRank/scroll.vue'
    import ElePilenum from './echarts/ElePilenum'
    import UserInfo from './echarts/UserInfo'
    import userCurve from './echarts/UserInfo/userCurve'
    import EleMap from './map'
    import ChargeState from './echarts/ChargeState'
    import Todayele from './echarts/Todayele'
    import DayMoney from './echarts/DayMoney'
    import UserAna from './echarts/UserAna'
    import MoElePile from './echarts/MoElePile'
    import YeElePile from './echarts/YeElePile'
    import OrderState from './echarts/OrderState'
    import Edialog from './modal'
    window._AMapSecurityConfig = {
        securityJsCode: SafeJsCode //安全密钥
    }
    export default {
        components: {
            countTo,
            SiteEleRank,
            ElePilenum,
            UserInfo,
            userCurve,
            EleMap,
            ChargeState,
            Todayele,
            DayMoney,
            UserAna,
            MoElePile,
            YeElePile,
            OrderState,
            Edialog
        },
        name: "big-screen",
        mounted(){
            this.$nextTick(()=>{
                this.height = document.documentElement.clientHeight-1
                setTimeout(()=>{
                    this.init()
                    this.getWeather()
                },500)
                this.time = setInterval(()=>{
                    this.init()
                },30000)
                this.time = setInterval(()=>{
                    this.getWeather()
                },21600000)//6小时刷新天气

                this.nowDate = dayjs(new Date()).format(this.dateFormat)
                this.time = setInterval(() => {
                    this.nowDate = dayjs(new Date()).format(this.dateFormat)
                }, 1000)

                if(!screenFull.isFullscreen){ //全屏
                    screenFull.request()
                }
                window.addEventListener("beforeunload", e => this.beforeunloadHandler(e))
                window.addEventListener("unload", e => this.unloadHandler(e))
            })
        },
        beforeDestroy(){
            clearInterval(this.time) //清除时间和循环获取数据
            this.time = null
        },
        destroyed() {
            window.removeEventListener("beforeunload", e =>this.beforeunloadHandler(e));
            window.removeEventListener("unload", e => this.unloadHandler(e));
        },
        data(){
            return {
                height: 0,
                nowDate: '',
                dateFormat: 'YYYY-MM-DD HH:mm:ss',
                nowWeather: {},
                time: null,
                siteNum: 0,
                chargingNum: 0,
                electricNum: 0,
                moneyNum: 0,
                // 站点位置
                chargeTime: 0,//累计充电时长
                chargeNumber: 0,//累计充电次数
                orderNumber: 0,//累计订单数量
                dayChargeAmount: 0,//当日充电量
                dayChargeNumber: 0,//当日充电次数
                dayChargeTime: 0,//当日充电时长
                dayChargePrice: 0,//当日充电费用
                dayOrderNumber: 0,//当日订单数量
                userNumber: 0,//用户数量
                //详情展示
                modal: false, //详情展示
                type: '',//打开方式,
                show: false,
                homeType: '',
            }
        },
        methods: {
            beforeunloadHandler() { //刷新重新监听
                window.addEventListener("unload", e => this.unloadHandler(e))
            },
            unloadHandler() { //关闭浏览器监听
                window.localStorage.removeItem('user')
                window.localStorage.removeItem('token')
            },
            userNum(num){
                this.userNumber = num
            },
            toLogout () { //退出
                this.$store.dispatch('LogOut').then(() => {
                    this.$router.push('/website')
                }).catch((err) => {
                    this.$Message.error(err)
                })
                screenFull.exit()
            },
            getWeather(){ //获取天气
                axios.get('https://restapi.amap.com/v3/weather/weatherInfo', {
                    params: {
                        key: AMapWebServiceKey,
                        city: '510100'
                    }
                }).then((res)=>{
                    if(res.data.infocode=='10000'){
                        this.nowWeather = res.data.lives[0]
                    }
                })
            },
            closeModal(){
               this.show = false
            },
            getSite(type){
                this.show = true
                this.homeType = type
            },
            init(){ //初始化所有图表
                GetSiteChargingMemKwhTotle().then((res)=>{ //统计电站，桩，电量，钱
                    let data = res.data
                    if(data.code === 0){
                        this.siteNum = data.data.total.siteNum
                        this.chargingNum = data.data.total.chargingNum
                        this.electricNum = data.data.total.electricNum
                        this.moneyNum = data.data.total.moneyNum

                        this.chargeTime = (data.data.total.minsHour/10000).toLocaleString()
                        this.chargeNumber = (data.data.total.orderNumber/10000).toLocaleString()
                        this.orderNumber = (data.data.total.orderNumber/10000).toLocaleString()
                        this.dayChargeAmount = (data.data.day.electricNum/100000000).toLocaleString()
                        this.dayChargeNumber = data.data.day.orderNumber.toLocaleString()
                        this.dayChargeTime = data.data.day.minsHour.toLocaleString()
                        this.dayChargePrice = data.data.day.moneyNum
                        this.dayOrderNumber = data.data.day.orderNumber
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .content::-webkit-scrollbar {
      width: 0 !important;
    }
    .content{
        color: #fff;
        background: url(./assets/pageBg.png) no-repeat center center;
        background-size: cover;
        // background: -webkit-radial-gradient(50% 35%, farthest-corner,#034f8e,#034987,#02366d,#002353);
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        .box-top{
            font-size: 20px;
            .decoration-6{
                width:300px;
                height:30px;
            }
            .decoration-8{
                width:300px;
                height:50px;
            }
            .decoration-10{
                height:5px;
            }
            .time{
                padding-top: 10px;
                padding-left: 10px;
            }
            .rigth-main{
                display: flex;
                justify-content: flex-end;
                .weather{
                    font-size: 14px;
                    padding-top: 10px;
                    padding-right: 10px;
                    text-align: right;
                }
                .icon{
                    cursor: pointer;
                    display: block;
                    text-align: right;
                    padding-top: 5px;
                    padding-right: 10px;
                }
            }
            .decoration-10-re{
                transform: rotateY(180deg);
                height:5px;
            }
            .text-box{
                text-align: center;
            }
        }
        .main-box{
            display: flex;
            justify-content: space-between;
            margin: 5px;
            .border-left{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex: 0 1 30%;
                width: 100%;
                .border-main{
                    display: flex;
                    width: 100%;
                    .site-pile{
                        width: 100%;
                        height: 260px;
                        .site-content{
                            height: 100%;
                            width: 90%;
                            margin: 0 auto;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            .border-title{
                                text-align: center;
                                font-size: 16px;
                                color: #b5c7da;
                            }
                            .ranking-board-one{
                                width:100%;
                                height:240px;
                            }
                        }
                        /deep/.ranking-info:hover{
                            .info-name{
                                display: block;
                            }
                        }
                        /deep/.rank{
                            color: #fff;
                        }
                        /deep/.ranking-info{
                            justify-content: space-between;
                        }
                        /deep/.ranking-column{
                            border-bottom: none;
                        }
                        /deep/.inside-column{
                            background-image: linear-gradient(to right, rgba(109, 236, 236, 0.4) 0%, rgb(109, 236, 236) 100%);
                        }
                        /deep/.info-name{
                            display: none;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
                .order-list{
                    width: 100%;
                    height: 200px;
                    .order-item{
                        padding: 10px;
                        .border-title{
                            font-size: 16px;
                            color: #b5c7da;
                        }
                        .ranking-board-one{
                            width:100%;
                            height:160px;
                        }
                    }
                }
            }
            .border-center{
                flex: 0 1 40%;
                width: 100%;
                height: 660px;
                .statistics{
                    width: 90%;
                    height: 100px;
                    display: flex;
                    margin: 0px auto;
                    margin-top: 20px;
                    .details-item{
                        height: 100%;
                        margin: 0 10px;
                        .details-list{
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            font-size: 16px;
                            border-radius: 10px;
                            .iconfont{
                                cursor: pointer;
                            }
                            .iconsize{
                                font-size: 30px;
                                color: #00BFFF;
                            }
                            .details-content{
                                display: flex;
                                .details-data{
                                    padding: 0 5px;
                                }
                            }
                        }
                    }
                }
                .map-container{
                    width: 100%;
                    height: 535px;
                    padding: 20px;
                    #container{
                        width: 100%;
                        height: 500px;
                        border-radius: 5px;
                    }
                }
            }
            .border-right{
                flex: 0 1 30%;
                width: 100%;
                height: 260px;
                .frequency{
                    height: 260px;
                    .charg-title{
                        padding: 5px 0 0 5px;
                        font-size: 16px;
                        color: #b5c7da;
                    }
                    .charg-status{
                        height: 220px;
                        width: 100%;
                    }
                    .liquidFill-item{
                        height: 220px;
                    }
                }
                .elecurve{
                    height: 200px;
                    .ele-title{
                        padding-left: 5px;
                        font-size: 16px;
                        color: #b5c7da;
                    }
                    .ranking-board-one{
                        width:100%;
                        height:170px;
                    }
                }
            }
            .user-top{
                /deep/.border-box-content{
                    display: flex;
                }
            }
            .middle-main{
                flex: 0 1 30%;
                width: 100%;
                height: 200px;
                .user-info{
                    width: 80%;
                    height: 200px;
                }
                .user-num{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    flex: 1;
                }
                .site-content{
                    height: 100%;
                    width: 96%;
                    margin: 0 auto;
                    display: flex;
                    flex-direction: column;
                    .operator-title{
                        padding-top: 10px;
                        font-size: 16px;
                        color: #b5c7da;
                    }
                    .ranking{
                        width: 100%;
                        height: 155px;
                    }
                }
                .user-num{
                    position: absolute;
                    left: 12px;
                    font-size: 16px;
                    color: #b5c7da;
                    top: 10px;
                    z-index: 10;
                }
            }
            .middle-center{
                flex: 0 1 40%;
                width: 100%;
                height: 200px;
                .order-info{
                    width: 100%;
                    height: 200px;
                }
            }
            .down-main{
                flex: 0 1 25%;
                width: 100%;
                height: 260px;
                .today-title{
                    padding: 5px 0 0 5px;
                    font-size: 16px;
                    color: #b5c7da;
                }
                .day-ranking{
                    width: 100%;
                    height: 228px;
                }
                .user-analysis{
                    width: 100%;
                    height: 228px;
                }
            }
            .footer{
                width: 100%;
                display: flex;
                justify-content: space-around;
                .footer-box{
                    display: flex;
                    flex-direction: column;
                    width: 150px;
                    .title{
                        position: relative;
                        display: block;
                        height: 25px;
                        line-height: 40px;
                        text-align: center;
                        border-radius: 2px 2px 0 0;
                    }
                    .title::before{
                        content: '';
                        position: absolute;
                        width: 80%;
                        height: 1px;
                        position: absolute;
                        background-color: #00BFFF;
                        left: 16px;
                        top: 32px;
                    }
                    .data{
                        display: block;
                        height: 55px;
                        font-size: 20px;
                        line-height: 55px;
                        color: #fff;
                        text-align: center;
                        border-radius: 0 0 2px 2px;
                    }
                }
            }
        }
    }
    /deep/.amap-markers{
        color: #fff;
        position: initial;
    }
    .amap-container {
        background: none;
    }
    /deep/.top {
        transform: translate(-50%,-110%);
    }
    /deep/.tipMarker {
        color: #555;
        background-color: rgba(255,254,239,0.8);
        border: 1px solid #7E7E7E;
        padding: 2px 6px;
        font-size: 12px;
        white-space: nowrap;
        display: inline-block;
    }
    /deep/.amap-logo{
        display: none !important;
    }
    /deep/.amap-copyright{
        display: none !important;
    }
</style>
