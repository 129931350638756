<template>
    <div>
        <Modal
            :title="site"
            :width="modalType == 'user'? 380 : 520"
            :mask-closable="false"
            :footer-hide="true"
            v-model="modeShow"
            @on-visible-change="close"
            class-name="vertical-center-modal">
            <template v-if="modalType=='ele'">
                <Table :no-data-text="nodata" :loading="loading" height="280" :columns="columns" :data="data"></Table>
                <Page :total="total" :current.sync="current" show-total @on-change="page"/>
            </template>
            <!-- <template v-else-if="modalType=='user'">
                <p class="user">微信：{{user.wx}}个， 支付宝：{{user.zfb}}个，APP微信：{{user.appwx}}个，APP支付宝：{{user.appzfb}}个，APP短信：{{user.appdx}}个</p>
            </template> -->
            <template v-else-if="modalType=='users'">
                <Table :no-data-text="nodata" :loading="loading" height="280" :columns="columns" :data="data"></Table>
                <Page :total="total" :current.sync="current" show-total @on-change="page"/>
            </template>
            <template v-else>
                <Table :no-data-text="nodata" :loading="loading" height="280" :columns="columns" :data="data"></Table>
                <Page :total="total" :current.sync="current" show-total @on-change="page"/>
            </template>
        </Modal>
    </div>
</template>

<script>
    import { siteInfo,electricNum,chargeState,registerType,userCout,siteStat,elePile } from '@/libs/apiBIgScreen'
    import table from './table'
    export default {
        name: 'index',
        props: {
            show:{
                type: Boolean,
                default: false
            },
            info:{
                type: Object,
            },
            type: {
                type: String,
            }
        },
        data(){
            return {
                modalType: '', //弹出框类型
                site: '', //站点名字
                modeShow: this.show, //对话框显隐
                params:{}, //传递的图表信息
                siteCode: '',//站点编码
                startTime: '', //开始时间
                companyID: '',//运营商ID
                chargeState: 0,//枪状态对应方式
                userId: '',//用户ID
                loading: false, //加载中
                total: 0, //条数
                current: 1, //页数
                homeType:'', //首页类型
                nodata: '',
                user: { //用户注册方式
                    wx: 0,
                    zfb: 0,
                    appwx: 0,
                    appzfb: 0,
                    appdx: 0
                },
                columns: [ //表格列
                    {
                        type: null,
                        width: 50,
                        render: (h, params) => {
                            return h(table, {
                                props: {
                                    type: params.row.type,
                                    row: params.row.list,
                                    userData: params.row.userData
                                }
                            })
                        }
                    },
                    {
                        title: '',
                        key: 'name'
                    },
                    {
                        title: '电量',
                        key: 'ele',
                        render: (h, params)=>{
                            return params.column.title == '' ? '' : params.column.title == '收入' ? (<span>{params.row.ele}元</span>) : params.column.title == '注册方式'||'手机号' ? (<span>{params.row.ele}</span>) : (<span>{params.row.ele}度</span>) //枪状态统计不要电量
                        }
                    }
                ],
                data: []//表格数据
            }
        },
        methods:{
            close(val){ //关闭对话框
                if(!val){
                    this.$emit('closeModal')
                }
            },
            page(num){ //翻页
                if(this.params.showType == 'expand'){
                    if(this.params.modalType){
                        this.GetUserCout(this.userId,num)
                    }else{
                        this.getSIteInfo(this.siteCode,num)
                    }
                }else if(this.params.eleType == 'day'){
                    this.getQueryEle(this.startTime,0,num)
                }else if(this.params.eleType == 'month'){
                    this.getQueryEle(this.startTime,1,num)
                }else if(this.params.eleType == 'year'){
                    this.getQueryEle(this.startTime,2,num)
                }else if(this.params.eleType == 'daymoney'){
                    this.getQueryEle(this.startTime,0,num,'money')
                }else if(this.params.eleType == 'chargestate'){
                    switch(this.chargeState){
                        case 0://空闲
                            this.GetChargeState(0,num)
                            break;
                        case 1: //使用中
                            this.GetChargeState(1,num)
                            break;
                        case 2: //离线
                            this.GetChargeState(2,num)
                            break;
                        case 3: //故障
                            this.GetChargeState(3,num)
                            break;
                    }
                }
                if(this.homeType == 'site'){
                    this.getSiteStat(num)
                }else if(this.homeType == 'pile'){
                    this.getElePile(num)
                }else if(this.homeType == 'ele'){
                    this.getQueryEle('','',num,'electric')
                }else if(this.homeType == 'money'){
                    this.getQueryEle('','',num,'money')
                }else if(this.homeType == 'userNum'){
                    this.getUserType(0,num)
                }else if(this.homeType == 'operatorNum'){
                    this.getUserType(1,num)
                }else if(this.homeType == 'enterpriseNum'){
                    this.getUserType(2,num)
                }else if(this.homeType == 'dayNew'){
                    this.getUserType(3,num)
                }
            },
            getSIteInfo(siteCode,num){ //获取电站电桩详细信息
                this.loading = true
                this.data = []
                this.nodata = '加载中...'
                siteInfo({
                    siteCode,
                    pageIndex: num,
                    pageSize: 10,
                    orderBy: 'electricity',
                    sort: 'descending'
                }).then((res)=>{
                    let data = res.data
                    if(data.code == 0){
                        this.loading = false
                        this.total = data.data.totalCount
                        if(this.total == 0){
                            this.nodata = '暂无数据'
                        }
                        data.data.dataSource.map((item)=>{
                            let list = []
                            item.electricData.map((item)=>{
                                list.push({
                                    num: item.electricCode.slice(-2),
                                    state: item.state
                                })
                            })
                            this.data.push({
                                name: item.chargeName,
                                ele: (item.electricity/10000).toLocaleString(),
                                chargeId: item.chargeId,
                                list
                            })
                        })
                    }
                })
            },
            getQueryEle(startTime,type,pageIndex,orderBy='electric'){ //电量统计 日收入统计
                this.loading = true
                this.data = []
                this.nodata = '加载中...'
                electricNum({
                    startTime,
                    type,
                    pageIndex,
                    pageSize: 10,
                    orderBy,
                    sort: 'descending'
                }).then((res)=>{
                    let data = res.data
                    if(data.code == 0){
                        this.loading = false
                        this.total = data.data.totalCount
                        if(this.total == 0){
                            this.nodata = '暂无数据'
                        }
                        data.data.dataSource.map((item)=>{
                            if(this.homeType == 'money'){
                                this.data.push({
                                    name: item.siteName,
                                    ele: (item.money).toLocaleString(),
                                })
                            }else if(this.params.eleType == 'daymoney'){
                                this.data.push({
                                    name: item.siteName,
                                    ele: (item.money).toLocaleString()
                                })
                            }else{
                                this.data.push({
                                    name: item.siteName,
                                    ele: (item.electric/10000).toLocaleString()
                                })
                            }
                        })
                    }
                })
            },
            GetChargeState(stateType,pageIndex){ //充电枪状态
                this.loading = true
                this.data = []
                this.nodata = '加载中...'
                chargeState({
                    stateType,
                    pageIndex,
                    pageSize: 10
                }).then((res)=>{
                    let data = res.data
                    if(data.code == 0){
                        this.loading = false
                        this.total = data.data.totalCount
                        if(this.total == 0){
                            this.nodata = '暂无数据'
                        }
                        data.data.dataSource.map((item)=>{
                            this.data.push({
                                name: item.chargeName,
                            })
                        })
                    }
                })
            },
            // getRegisterType(){ //用户注册方式统计
            //     this.loading = true
            //     registerType().then((res)=>{
            //         let data = res.data
            //         if(data.code == 0){
            //             this.loading = false
            //             this.user.wx = data.data[3].count
            //             this.user.zfb = data.data[5].count
            //             this.user.appwx = data.data[4].count
            //             this.user.appzfb = data.data[2].count
            //             this.user.appdx = data.data[1].count
            //         }
            //     })
            // },
            GetUserCout(userId,pageIndex){ //充电用户分析
                this.loading = true
                this.data = []
                this.nodata = '加载中...'
                userCout({
                    userId,
                    pageIndex,
                    pageSize: 10
                }).then((res)=>{
                    let data = res.data
                    if(data.code == 0){
                        this.loading = false
                        this.total = data.data.totalCount
                        if(this.total == 0){
                            this.nodata = '暂无数据'
                        }
                        data.data.dataSource.map((item)=>{
                            this.data.push({
                                name: item.siteName,
                                type: 'users',
                                userData: item
                            })
                        })
                    }
                })
            },
            getSiteStat(pageIndex){ //电站列表
                this.loading = true
                this.data = []
                this.nodata = '加载中...'
                siteStat({
                    pageIndex,
                    pageSize:10
                }).then((res)=>{
                    let data = res.data
                    if(data.code == 0){
                        this.loading = false
                        this.total = data.data.totalCount
                        if(this.total == 0){
                            this.nodata = '暂无数据'
                        }
                        data.data.dataSource.map((item)=>{
                            this.data.push({
                                name: item.siteName
                                
                            })
                        })
                    }
                })
            },
            getElePile(pageIndex){  //电桩列表
                this.loading = true
                this.data = []
                this.nodata = '加载中...'
                elePile({
                    pageIndex,
                    pageSize:10
                }).then((res)=>{
                    let data = res.data
                    if(data.code == 0){
                        this.loading = false
                        this.total = data.data.totalCount
                        if(this.total == 0){
                            this.nodata = '暂无数据'
                        }
                        data.data.dataSource.map((item)=>{
                            this.data.push({
                                name: item.chargeName
                            })
                        })
                    }
                })
            },
            userNum(){
                this.loading = true
                this.data = []
                this.nodata = '加载中...'
                this.getUserType(0)
            },
            operatorNum(){
                this.loading = true
                this.data = []
                this.nodata = '加载中...'
                this.getUserType(1)
            },
            enterpriseNum(){
                this.loading = true
                this.data = []
                this.nodata = '加载中...'
                this.getUserType(2)
            },
            dayNew(){
                this.loading = true
                this.data = []
                this.nodata = '加载中...'
                this.getUserType(3)
            },
            getUserType(type,pageIndex){
                this.data = []
                registerType({
                    type,
                    pageIndex,
                    pageSize: 10
                }).then((res)=>{
                    let data = res.data
                    if(data.code == 0){
                        this.loading = false
                        this.total = data.data.totalCount
                        if(this.total == 0){
                            this.nodata = '暂无数据'
                        }
                        if(type == 0){
                            if(this.columns[3]){
                                this.columns.splice(-1)
                            }
                            data.data.dataSource.map((item)=>{
                                this.data.push({
                                    name: item.userPhone,
                                    ele:item.userSourceName
                                })
                            })
                        }else if(type == 1){
                            if(this.columns[3]){
                                this.columns.splice(-1)
                            }
                            data.data.dataSource.map((item)=>{
                                this.data.push({
                                    name: item.userName
                                })
                            })
                        }else if(type == 2){
                            if(this.columns[3]){
                                this.columns.splice(-1)
                            }
                            data.data.dataSource.map((item)=>{
                                this.data.push({
                                    name: item.userName
                                })
                            })
                        }
                        else if(type == 3){
                            if(!this.columns[0].type){
                                this.columns[0].type = "index"
                            }
                            if(this.columns[3]){
                                this.columns.splice(-1)
                            }
                            data.data.dataSource.map((item)=>{
                                this.data.push({
                                    name: item.userName,
                                    ele: item.userPhone,
                                    reType: item.userSourceName
                                })
                            })
                            this.columns.push({
                                title: '注册方式',
                                key: 'reType'
                            })
                            
                        }
                    }
                })
            }
        },
        watch: {
            show(){ //控制对话框显示隐藏
                this.modeShow = this.show
            },
            info(val){ //点击图表后获取到的数据
                if(val.data){
                    this.siteCode = val.data.siteCode //电站code
                    this.startTime = val.data.time //电量开始时间
                    this.companyID = val.data.companyID //运营商ID
                }
                this.current = 1 //默认回到第一页
                this.params = val //传递的图表信息
                this.chargeState = val.dataIndex //电枪选中状态
                if(val.modalType == 'user'){ //弹出框展示类型
                    // this.modalType = 'user'
                    // this.site = '用户注册方式统计'
                    // this.getRegisterType()
                }else if(val.modalType == 'users'){
                    this.modalType = 'users'
                    this.site = val.name
                    this.userId = val.data.userId
                    this.GetUserCout(val.data.userId,1)
                }else if(val.modalType == 'userType'){
                    this.modalType = 'users'
                    this.site = ''
                }else{
                    this.modalType = 'ele'
                }
                switch(val.showType)
                {
                    case 'expand': //充电站电量排行
                        if(val.modalType == 'users'){
                            this.columns[1].title = '站点'
                            this.columns[2].title = ''
                        }else{
                            this.columns[1].title = '桩号'
                            this.site = val.name //站点名字
                            this.getSIteInfo(val.data.siteCode,1)
                        }
                        break;
                    case 'index':
                        this.columns[1].title = '站点'
                        if(val.eleType == 'day'){ //日电量
                            this.site = val.data.time + '点'
                            this.getQueryEle(val.data.time,0,1)
                        }else if(val.eleType == 'month'){ //月电量
                            this.site = val.data.time + '日'
                            this.getQueryEle(val.data.time,1,1)
                        }else if(val.eleType == 'year'){ //年电量
                            this.site = val.data.time + '月'
                            this.getQueryEle(val.data.time,2,1)
                        }else if(val.eleType == 'daymoney'){ //日收入
                            this.site = val.name
                            this.getQueryEle(val.data.time,0,1,'money')
                            this.columns[2].title = '收入'
                        }else if(val.eleType == 'chargestate'){ //桩状态
                            this.columns[1].title = '桩号'
                            this.columns[2].title = ''
                            this.site = val.name //站点名字
                            switch(val.dataIndex){
                                case 0://空闲
                                    this.GetChargeState(0,1)
                                    break;
                                case 1: //使用
                                    this.GetChargeState(1,1)
                                    break;
                                case 2: //离线
                                    this.GetChargeState(2,1)
                                    break;
                                case 3: //故障
                                    this.GetChargeState(3,1)
                                    break;
                            }
                        }
                        break;
                }
                this.columns[0].type = val.showType //弹出框展示类型
            },
            type(val){
                this.current = 1 //默认回到第一页
                if(val=='site'){
                    this.homeType = 'site'
                    this.getSiteStat(1)
                    this.columns[0].type = 'index'
                    this.columns[1].title = '站点'
                    this.columns[2].title = ''
                }else if(val=='pile'){
                    this.homeType = 'pile'
                    this.getElePile(1)
                    this.columns[0].type = 'index'
                    this.columns[1].title = '电桩'
                    this.columns[2].title = ''
                }else if(val=='ele'){
                    this.homeType = 'ele'
                    this.getQueryEle('','',1)
                    this.columns[0].type = 'index'
                    this.columns[1].title = '站点'
                    this.columns[2].title = '电量'
                }else if(val=='money'){
                    this.homeType = 'money'
                    this.columns[0].type = 'index'
                    this.getQueryEle('','',1,'money')
                    this.columns[1].title = '站点'
                    this.columns[2].title = '收入'
                }else if(val=='userNum'){
                    this.homeType = 'userNum'
                    this.columns[0].type = 'index'
                    this.columns[1].title = '普通用户'
                    this.columns[2].title = '注册方式'
                    this.userNum()
                }else if(val=='operatorNum'){
                    this.homeType = 'operatorNum'
                    this.columns[0].type = 'index'
                    this.columns[1].title = '运营商'
                    this.columns[2].title = ''
                    this.operatorNum()
                }else if(val=='enterpriseNum'){
                    this.homeType = 'enterpriseNum'
                    this.columns[0].type = 'index'
                    this.columns[1].title = '企业用户'
                    this.columns[2].title = ''
                    this.enterpriseNum()
                }else if(val=='dayNew'){
                    this.homeType = 'dayNew'
                    this.columns[0].type = 'index'
                    this.columns[1].title = '新增用户'
                    this.columns[2].title = '手机号'
                    this.dayNew()
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .vertical-center-modal{
        display: flex;
        align-items: center;
        justify-content: center;
        .ivu-modal{
            top: 0;
        }
    }
    .user{
        color: #fff;
    }
    /deep/.ivu-col-span-6{
        flex: none;
        max-width: 100%;
    }
    /deep/.ivu-table-cell{
        padding-right: 0px;
    }
    /deep/.ivu-modal-content{
        --border-size: 3px;
        --border-angle: 0turn;
        background-image: conic-gradient(from var(--border-angle), #242429, #242429 50%, #242429), conic-gradient(from var(--border-angle), #242429 20%, #00BFFF);
        background-size: calc(100% - (var(--border-size) * 2)) calc(100% - (var(--border-size) * 2)), cover;
        background-position: center center;
        background-repeat: no-repeat;
        -webkit-animation: bg 3s linear infinite;
        animation: bg 3s linear infinite;
    }
    @-webkit-keyframes bg {
        to {
            --border-angle: 1turn;
        }
    }
    @keyframes bg {
        to {
            --border-angle: 1turn;
        }
    }
    @property --border-angle {
        syntax: "<angle>";
        inherits: true;
        initial-value: 0turn;
    }
    /deep/.ivu-modal-header-inner{
        color: #fff;
    }
    /deep/.ivu-page-total{
        color: #fff;
    }
    /deep/.ivu-page-prev{
        min-width: 25px;
        height: 25px;
        line-height: 25px;
    }
    /deep/.ivu-page-next{
        min-width: 25px;
        height: 25px;
        line-height: 25px;
    }
    /deep/.ivu-page-item-active{
        background-color: #00BFFF !important;
    }
    /deep/.ivu-icon-ios-close{
        color: #fff;
    }
    /deep/.ivu-table-body{
        background-color: #20242a;
    }
    /deep/.ivu-table td{
        color: #fff;
        background-color: #20242a;
    }
    /deep/.ivu-table th{
        color: #fff;
        background-color: #20242a;
    }
    /deep/.ivu-table-overflowY{
        color: #fff;
        background-color: #20242a;
    }
    /deep/.ivu-table-header>table{
        width: 100% !important;
    }
    /deep/.ivu-table-overflowY>table{
        width: 100% !important;
    }
    /deep/.ivu-modal-mask{
        background-color: transparent;
    }
    /deep/.ivu-spin-fix{
        background-color: rgba(0,0,0,.1);
    }
    /deep/.ivu-table-overflowY::-webkit-scrollbar {
        width: 0px;		//滚动条宽度
    }
    // /deep/.ivu-table-overflowY::-webkit-scrollbar-thumb {		//上层
    //     border-radius: 10px;	//滚动条圆弧半径
    //     -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);	//滚动条阴影
    //     background: rgba(150, 11, 11, 0.2);	//背景颜色
    // }
    // /deep/.ivu-table-overflowY::-webkit-scrollbar-track {	//底层
    //     -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    //     border-radius: 0;
    //     background: rgba(0, 0, 0, 0.1);
    // }
</style>