<template>
    <div ref="orderstate" style="width: 100%;height: 170px;"></div>
</template>

<script>
    import * as echarts from 'echarts';
    import { GetOrderInfoTotal} from '@/libs/apiBIgScreen'
    export default {
        name:'index',
        mounted(){
            this.$nextTick(()=>{
                setTimeout(()=>{
                    this.init()
                },500)
            })
            this.time = setInterval(()=>{
                this.init()
                clearInterval(this.tiemSite)
                this.tiemSite = null
            },30000)
        },
        beforeDestroy(){
            clearInterval(this.time)
            this.time = null
        },
        methods: {
            init(){
                GetOrderInfoTotal().then((res)=>{ //订单非法率，失败率，临停率，完成率，企业用户，运营商用户，普通用户，每日新增用户统计
                    let data = res.data
                    if(data.code === 0){
                        let illegalBillRate = data.data.illegalBillRate
                        let faultRate = data.data.faultRate
                        let completeRate = data.data.completeRate
                        let complaintRate = data.data.complaintRate
                        this.dailyAddUserNum = data.data.dailyAddUserNum
                        if (this.$refs.orderstate == null) {
                            return
                        }
                        let myChartOrder = echarts.getInstanceByDom(this.$refs.orderstate);
                        if (myChartOrder == null) { // 如果不存在，就进行初始化。
                            myChartOrder = echarts.init(this.$refs.orderstate)
                        }
                        window.addEventListener("resize", () => {
                            myChartOrder.resize()
                        })
                        let dataList = [
                            { value: completeRate, name: '完成率' },
                            { value: faultRate, name: '非法率' },
                            { value: illegalBillRate, name: '失败率' },
                            { value: complaintRate, name: '投诉率' }
                        ];
                        const colorList = ['#50CCCB','#4D88FE','#FFBF3C','#FF0000']
                        let richColor = {}
                        colorList.forEach((item, idx) => {
                            richColor[`color${idx}`] = {
                                fontSize: 12,
                                color: item
                            }
                        })
                        let optionOrder = {
                            tooltip: {
                                show: true,
                                formatter: function (param) {
                                    return `
                                    ${param.marker}
                                    ${param.name} :
                                    ${param.value.toLocaleString()}%`
                                }
                            },
                            series: [
                                {
                                    type: 'pie',
                                    radius: '82%',
                                    center: ['50%', '50%'],
                                    label: {
                                        formatter: params => {
                                            return `{color${params.dataIndex}|${params.name}(${params.value})}`
                                        },
                                        rich: richColor
                                    },
                                    labelLine: {
                                        length: 10,
                                        length2: 60,
                                        lineStyle: {
                                            width: 3
                                        }
                                    },
                                    itemStyle: {
                                        normal: {
                                            color: function (params) {
                                                return colorList[params.dataIndex]
                                            }
                                        }
                                    },
                                    data: dataList
                                }
                            ]
                        };
                        myChartOrder.setOption(optionOrder)
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>

</style>