<template>
    <div>
        <div ref="elepilenum" style="width: 100%;height: 160px;"></div>
        <Edialog :show="show" :info="info" @closeModal="closeModal"/>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    import { SiteOrderNumRaning} from '@/libs/apiBIgScreen'
    import Edialog from '../../modal'
    export default {
        components: {
            Edialog
        },
        name: 'index',
        mounted(){
            this.$nextTick(()=>{
                setTimeout(()=>{
                    this.init()
                },500)
            })
            this.time = setInterval(()=>{
                this.init()
                clearInterval(this.tiemSite)
                this.tiemSite = null
            },60000) //一分钟
        },
        beforeDestroy(){
            clearInterval(this.time)
            this.time = null
        },
        data(){
            return {
                show: false,
                info: {}
            }
        },
        methods: {
            closeModal(){
               this.show = false 
            },
            init(){
                SiteOrderNumRaning({//充电桩数量排行
                    type: 0,
                    topNum: 10
                }).then((res)=>{
                    let data = res.data
                    if(data.code === 0){
                        if (this.$refs.elepilenum == null) {
                            return
                        }
                        let myChart = echarts.getInstanceByDom(this.$refs.elepilenum);
                        if (myChart == null) { // 如果不存在，就进行初始化。
                            myChart = echarts.init(this.$refs.elepilenum)
                        }
                        window.addEventListener("resize", () => {
                            myChart.resize()
                        })
                        myChart.on('click', (params) => {
                            this.show = true
                            this.info = Object.assign({},{showType:'expand'},params)
                        })
                        let sitelist = []
                        let num = 0
                        data.data.map((item)=>{
                            item.value = num+=20
                            sitelist.push({ value: item.value, name: item.siteName , num: item.number, siteCode: item.siteCode})
                        })
                        let leftList = []
                        let rightList = []
                        sitelist.slice(0,5).forEach((item)=>{
                            leftList.push(item.name)
                        })
                        sitelist.slice(5,10).forEach((item)=>{
                            rightList.push(item.name)
                        })
                        let option = {
                            legend:[
                                {
                                    orient: 'vertical',
                                    icon: 'circle',
                                    align: 'left',
                                    top: '24',
                                    left:'5',
                                    itemWidth: 12,
                                    itemHeight: 12,
                                    data: leftList,
                                    textStyle: {
                                        color: '#fff',
                                        fontSize: 12,
                                        align: 'left',
                                        // 文字块背景色，一定要加上，否则对齐不会生效
                                        backgroundColor: "transparent",
                                        rich: {
                                            b: {
                                                width: 200,
                                            }
                                        }
                                    },
                                    formatter:(value)=>{
                                        return value.substr(0,6) + '...'
                                    }
                                },
                                {
                                    orient: 'vertical',
                                    icon: 'circle',
                                    align: 'right',
                                    top: '24',
                                    right:'5',
                                    itemWidth: 12,
                                    itemHeight: 12,
                                    data: rightList,
                                    textStyle: {
                                        color: '#fff',
                                        fontSize: 12,
                                        align: 'left',
                                        // 文字块背景色，一定要加上，否则对齐不会生效
                                        backgroundColor: "transparent",
                                        rich: {
                                            a: {
                                                width: 200,
                                            }
                                        }
                                    },
                                    formatter:(value)=>{
                                        return value.substr(0,6) + '...'
                                    }
                                }
                            ],
                            series: [
                                {
                                    zlevel: 1,
                                    name: '排行',
                                    type: 'funnel',
                                    left: '20%',
                                    top: 2,
                                    bottom: 0,
                                    width: '60%',
                                    sort: 'ascending',
                                    gap: 2,
                                    label: {
                                        show: false,
                                        position: 'inside',
                                        textStyle:{
                                            color: '#fff',
                                            fontSize: 14
                                        },
                                        width: '200px',
                                        formatter: (data) => {
                                            return data.data.name.substr(0,10)+ '...' + ' ' + data.data.num + '个'
                                        },
                                    },
                                    labelLine: {
                                        show: false,
                                        length: 40,
                                        lineStyle: {
                                            width: 1,
                                            type: 'solid'
                                        }
                                    },
                                    itemStyle: {
                                        borderColor: '#fff',
                                        borderWidth: 1
                                    },
                                    emphasis: {
                                        label: {
                                            fontSize: 16
                                        }
                                    },
                                    data: sitelist
                                }
                            ]
                        }
                        myChart.setOption(option);
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>

</style>