<template>
    <div>
        <!-- <div ref="ordinaryUser" class="user-info"></div>
        <div class="user-box">
            <div ref="operatorUser" class="user-item"></div>
            <div ref="enterpriseUser" class="user-item"></div>
            <div ref="addUser" class="user-item"></div>
        </div> -->
        <div class="user">
            <div class="data" @click="userNum">
                <span>普通用户</span>
                <span>{{userInfoCount}}K个</span>
            </div>
            <div class="data" @click="operatorNum">
                <span>运营商</span>
                <span>{{operatorCount}}个</span>
            </div>
            <div class="data" @click="enterpriseNum">
                <span>企业用户</span>
                <span>{{enterpriseCount}}个</span>
            </div>
            <div class="data" @click="dayNew">
                <span>今日新增用户</span>
                <span>{{dailyAddUserNum}}个</span>
            </div>
        </div>
        <Edialog :show="show" :info="info" :type="homeType" @closeModal="closeModal"/>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    import { GetOrderInfoTotal} from '@/libs/apiBIgScreen'
    import Edialog from '../../modal'
    export default {
        components: {
            Edialog
        },
        name: 'index',
        mounted(){
            this.$nextTick(()=>{
                setTimeout(()=>{
                    this.init()
                },500)
            })
            this.time = setInterval(()=>{
                this.init()
                clearInterval(this.tiemSite)
                this.tiemSite = null
            },30000)
        },
        beforeDestroy(){
            clearInterval(this.time)
            this.time = null
        },
        data(){
            return {
                show: false,
                info: {},
                userInfoCount: '',
                operatorCount: '',
                enterpriseCount: '',
                dailyAddUserNum: '',
                homeType: ''
            }
        },
        methods: {
            closeModal(){
               this.show = false 
            },
            userNum(){
                this.show = true
                this.info = {modalType:'userType'}
                this.homeType = 'userNum'
            },
            operatorNum(){
                this.show = true
                this.info = {modalType:'userType'}
                this.homeType = 'operatorNum'
            },
            enterpriseNum(){
                this.show = true
                this.info = {modalType:'userType'}
                this.homeType = 'enterpriseNum'
            },
            dayNew(){
                this.show = true
                this.info = {modalType:'userType'}
                this.homeType = 'dayNew'
            },
            init(){
                GetOrderInfoTotal().then((res)=>{
                    let data = res.data
                    if(data.code === 0){
                        this.userInfoCount = data.data.userInfoCount/1000
                        this.operatorCount = data.data.operatorCount
                        this.enterpriseCount = data.data.enterpriseCount
                        this.dailyAddUserNum = data.data.dailyAddUserNum
                        this.$emit('userNumber',data.data.userInfoCount + data.data.operatorCount + data.data.enterpriseCount)
                        //普通用户
                        // if (this.$refs.ordinaryUser == null) {
                        //     return
                        // }
                        // let ordinaryUser = echarts.getInstanceByDom(this.$refs.ordinaryUser);
                        // if (ordinaryUser == null) { // 如果不存在，就进行初始化。
                        //     ordinaryUser = echarts.init(this.$refs.ordinaryUser)
                        // }
                        // window.addEventListener("resize", () => {
                        //     ordinaryUser.resize()
                        // })
                        // ordinaryUser.on('click', (params) => {
                        //     this.show = true
                        //     this.info = Object.assign({},{modalType:'user'},params)
                        // })
                        // let datas = [
                        //     {
                        //         'name': '普通用户',
                        //         'value': userInfoCount
                        //     }, 
                        // ]

                        // let datax = datas.map(item => {
                        //     return item.name
                        // })
                        // let optionUser = {
                        //     tooltip: {
                        //         trigger: 'axis',
                        //         position: (point, params, dom, rect, size)=> {
                        //             return [point[0] + 20, point[1] + 20]  //返回x、y（横向、纵向）两个点的位置
                        //         },
                        //         axisPointer: {
                        //             type: 'none'
                        //         },
                        //         formatter: function(params) {
                        //             return params[0].name + ': ' + params[0].value*1000+'个';
                        //         }
                        //     },
                        //     grid:{
                        //         bottom:'0%',
                        //     },
                        //     xAxis: {
                        //         data: datax,
                        //         axisTick: {
                        //             show: false
                        //         },
                        //         axisLine: {
                        //             show: false
                        //         },
                        //         axisLabel: {
                        //             show: false,
                        //             textStyle: {
                        //                 color: '#444444'
                        //             }
                        //         }
                        //     },
                        //     yAxis: {
                        //         max: 100,
                        //         splitLine: {
                        //             show: false
                        //         },
                        //         axisTick: {
                        //             show: false
                        //         },
                        //         axisLine: {
                        //             show: false
                        //         },
                        //         axisLabel: {
                        //             show: false
                        //         }
                        //     },
                        //     series: [{
                        //         name: 'hill',
                        //         type: 'pictorialBar',
                        //         barCategoryGap: '0%',
                        //         symbol: 'path://M10 600 Q 95 0 180 600',
                        //         label: {
                        //             normal: {
                        //                 show: true,
                        //                 position: 'top',
                        //                 formatter: ['{c|{c}K}', '{b|{b}}'].join('\n'),
                        //                 rich: {
                        //                     b: {
                        //                         color: '#aaa',
                        //                         padding: [2, 0, 0, 0],
                        //                         align: 'center',
                        //                         fontSize: 18,

                        //                     },
                        //                     c: {
                        //                         color: '#00EEFF',
                        //                         padding: [2, 0, 0, 0],

                        //                         fontSize: 20,
                        //                         fontWeight: 'bolder'
                        //                     }
                        //                 },

                        //             }
                        //         },
                        //         itemStyle: {
                        //             emphasis: {
                        //                 opacity: 1
                        //             }
                        //         },
                        //         data: [
                        //             {
                        //                 value: datas[0].value,
                        //                 symbolSize: ['100%', '300%'],
                        //                 // symbolPosition: 'end',
                        //                 itemStyle: {
                        //                     normal: {
                        //                         opacity: 1,
                        //                         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        //                             offset: 0,
                        //                             color: '#63FA63'
                        //                         }, {
                        //                             offset: 1,
                        //                             color: '#01CC98'
                        //                         }]),
                        //                     },
                        //                     emphasis: {
                        //                         opacity: 1,
                        //                         color: '#89DA4F'
                        //                     }

                        //                 },

                        //             }, 
                        //         ],
                        //     }]
                        // };
                        // ordinaryUser.setOption(optionUser)
                        // //运营商
                        // if (this.$refs.operatorUser == null) {
                        //     return
                        // }
                        // let operatorUser = echarts.getInstanceByDom(this.$refs.operatorUser);
                        // if (operatorUser == null) { // 如果不存在，就进行初始化。
                        //     operatorUser = echarts.init(this.$refs.operatorUser)
                        // }
                        // window.addEventListener("resize", () => {
                        //     operatorUser.resize()
                        // })
                        // operatorUser.on('click', (params) => {
                        //     this.show = true
                        //     this.info = Object.assign({},{modalType:'user'},params)
                        // })
                        // let datas2 = [
                        //     {
                        //         'name': '运营商',
                        //         'value': operatorCount
                        //     }
                        // ]
                        // let datax2 = datas2.map(item => {
                        //     return item.name
                        // })
                        // let optionUser2 = {
                        //     tooltip: {
                        //         trigger: 'axis',
                        //         axisPointer: {
                        //             type: 'none'
                        //         },
                        //         formatter: function(params) {
                        //             return params[0].name + ': ' + params[0].value +'个';
                        //         }
                        //     },
                        //     grid:{
                        //         bottom:'0%',
                        //     },
                        //     xAxis: {
                        //         data: datax2,
                        //         axisTick: {
                        //             show: false
                        //         },
                        //         axisLine: {
                        //             show: false
                        //         },
                        //         axisLabel: {
                        //             show: false,
                        //             textStyle: {
                        //                 color: '#444444'
                        //             }
                        //         }
                        //     },
                        //     yAxis: {
                        //         max: 100,
                        //         splitLine: {
                        //             show: false
                        //         },
                        //         axisTick: {
                        //             show: false
                        //         },
                        //         axisLine: {
                        //             show: false
                        //         },
                        //         axisLabel: {
                        //             show: false
                        //         }
                        //     },
                        //     series: [{
                        //         name: 'hill',
                        //         type: 'pictorialBar',
                        //         barCategoryGap: '0%',
                        //         symbol: 'path://M10 600 Q 95 0 180 600',
                        //         label: {
                        //             normal: {
                        //                 show: true,
                        //                 position: 'top',
                        //                 formatter: ['{c|{c}个}', '{b|{b}}'].join('\n'),
                        //                 rich: {
                        //                     b: {
                        //                         color: '#aaa',
                        //                         padding: [2, 0, 0, 0],
                        //                         align: 'center',
                        //                         fontSize: 18,

                        //                     },
                        //                     c: {
                        //                         color: '#00EEFF',
                        //                         padding: [2, 0, 0, 0],

                        //                         fontSize: 20,
                        //                         fontWeight: 'bolder'
                        //                     }
                        //                 },

                        //             }
                        //         },
                        //         itemStyle: {
                        //             emphasis: {
                        //                 opacity: 1
                        //             }
                        //         },
                        //         data: [
                        //             {
                        //                 value: datas2[0].value,
                        //                 symbolSize: ['100%', '300%'],
                        //                 // symbolPosition: 'end',
                        //                 itemStyle: {
                        //                     normal: {
                        //                         opacity: 1,
                        //                         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        //                             offset: 0,
                        //                             color: '#EAB700'
                        //                         }, {
                        //                             offset: 1,
                        //                             color: '#EDB900'
                        //                         }]),
                        //                     },
                        //                     emphasis: {
                        //                         opacity: 1,
                        //                         color: '#FFD700'
                        //                     }

                        //                 },
                        //                 z: 10
                        //             }
                        //         ],
                        //     }]
                        // };
                        // operatorUser.setOption(optionUser2)
                        // //企业用户
                        // if (this.$refs.enterpriseUser == null) {
                        //     return
                        // }
                        // let enterpriseUser = echarts.getInstanceByDom(this.$refs.enterpriseUser);
                        // if (enterpriseUser == null) { // 如果不存在，就进行初始化。
                        //     enterpriseUser = echarts.init(this.$refs.enterpriseUser)
                        // }
                        // window.addEventListener("resize", () => {
                        //     enterpriseUser.resize()
                        // })
                        // enterpriseUser.on('click', (params) => {
                        //     this.show = true
                        //     this.info = Object.assign({},{modalType:'user'},params)
                        // })
                        // let datas3 = [
                        //     {
                        //         'name': '企业用户',
                        //         'value': enterpriseCount
                        //     }
                        // ]
                        // let datax3 = datas3.map(item => {
                        //     return item.name
                        // })
                        // let optionUser3 = {
                        //     tooltip: {
                        //         trigger: 'axis',
                        //         axisPointer: {
                        //             type: 'none'
                        //         },
                        //         formatter: function(params) {
                        //             return params[0].name + ': ' + params[0].value +'个';
                        //         }
                        //     },
                        //     grid:{
                        //         bottom:'0%',
                        //     },
                        //     xAxis: {
                        //         data: datax3,
                        //         axisTick: {
                        //             show: false
                        //         },
                        //         axisLine: {
                        //             show: false
                        //         },
                        //         axisLabel: {
                        //             show: false,
                        //             textStyle: {
                        //                 color: '#444444'
                        //             }
                        //         }
                        //     },
                        //     yAxis: {
                        //         max: 100,
                        //         splitLine: {
                        //             show: false
                        //         },
                        //         axisTick: {
                        //             show: false
                        //         },
                        //         axisLine: {
                        //             show: false
                        //         },
                        //         axisLabel: {
                        //             show: false
                        //         }
                        //     },
                        //     series: [{
                        //         name: 'hill',
                        //         type: 'pictorialBar',
                        //         barCategoryGap: '0%',
                        //         symbol: 'path://M10 600 Q 95 0 180 600',
                        //         label: {
                        //             normal: {
                        //                 show: true,
                        //                 position: 'top',
                        //                 formatter: ['{c|{c}个}', '{b|{b}}'].join('\n'),
                        //                 rich: {
                        //                     b: {
                        //                         color: '#aaa',
                        //                         padding: [2, 0, 0, 0],
                        //                         align: 'center',
                        //                         fontSize: 18,

                        //                     },
                        //                     c: {
                        //                         color: '#00EEFF',
                        //                         padding: [2, 0, 0, 0],

                        //                         fontSize: 20,
                        //                         fontWeight: 'bolder'
                        //                     }
                        //                 },

                        //             }
                        //         },
                        //         itemStyle: {
                        //             emphasis: {
                        //                 opacity: 1
                        //             }
                        //         },
                        //         data: [
                        //             {
                        //                 value: datas3[0].value,
                        //                 symbolSize: ['100%', '100%'],
                        //                 // symbolPosition: 'end',
                        //                 itemStyle: {
                        //                     normal: {
                        //                         opacity: 1,
                        //                         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        //                             offset: 0,
                        //                             color: '#18CDF2'
                        //                         }, {
                        //                             offset: 1,
                        //                             color: '#3378FF'
                        //                         }]),
                        //                     },
                        //                     emphasis: {
                        //                         opacity: 1,
                        //                         color: '#1E90FF'
                        //                     }
                        //                 }
                        //             }
                        //         ],
                        //     }]
                        // };
                        // enterpriseUser.setOption(optionUser3)
                        // //每日新增用户
                        // if (this.$refs.addUser == null) {
                        //     return
                        // }
                        // let addUser = echarts.getInstanceByDom(this.$refs.addUser);
                        // if (addUser == null) { // 如果不存在，就进行初始化。
                        //     addUser = echarts.init(this.$refs.addUser)
                        // }
                        // window.addEventListener("resize", () => {
                        //     addUser.resize()
                        // })
                        // addUser.on('click', (params) => {
                        //     this.show = true
                        //     this.info = Object.assign({},{modalType:'user'},params)
                        // })
                        // let datas4 = [
                        //     {
                        //         'name': '今日新增用户',
                        //         'value': dailyAddUserNum
                        //     }
                        // ]
                        // let datax4 = datas4.map(item => {
                        //     return item.name
                        // })
                        // let optionUser4 = {
                        //     tooltip: {
                        //         trigger: 'axis',
                        //         axisPointer: {
                        //             type: 'none'
                        //         },
                        //         formatter: function(params) {
                        //             return params[0].name + ': ' + params[0].value +'个';
                        //         }
                        //     },
                        //     grid:{
                        //         bottom:'0%',
                        //     },
                        //     xAxis: {
                        //         data: datax4,
                        //         axisTick: {
                        //             show: false
                        //         },
                        //         axisLine: {
                        //             show: false
                        //         },
                        //         axisLabel: {
                        //             show: false,
                        //             textStyle: {
                        //                 color: '#444444'
                        //             }
                        //         }
                        //     },
                        //     yAxis: {
                        //         max: 100,
                        //         splitLine: {
                        //             show: false
                        //         },
                        //         axisTick: {
                        //             show: false
                        //         },
                        //         axisLine: {
                        //             show: false
                        //         },
                        //         axisLabel: {
                        //             show: false
                        //         }
                        //     },
                        //     series: [{
                        //         name: 'hill',
                        //         type: 'pictorialBar',
                        //         barCategoryGap: '0%',
                        //         symbol: 'path://M10 600 Q 95 0 180 600',
                        //         label: {
                        //             normal: {
                        //                 show: true,
                        //                 position: 'top',
                        //                 formatter: ['{c|{c}个}', '{b|{b}}'].join('\n'),
                        //                 rich: {
                        //                     b: {
                        //                         color: '#aaa',
                        //                         padding: [2, 0, 0, 0],
                        //                         align: 'center',
                        //                         fontSize: 18,

                        //                     },
                        //                     c: {
                        //                         color: '#00EEFF',
                        //                         padding: [2, 0, 0, 0],

                        //                         fontSize: 20,
                        //                         fontWeight: 'bolder'
                        //                     }
                        //                 },

                        //             }
                        //         },
                        //         itemStyle: {
                        //             emphasis: {
                        //                 opacity: 1
                        //             }
                        //         },
                        //         data: [
                        //             {
                        //                 value: datas4[0].value,
                        //                 symbolSize: ['100%', '50%'],
                        //                 // symbolPosition: 'end',
                        //                 itemStyle: {
                        //                     normal: {
                        //                         opacity: 1,
                        //                         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        //                             offset: 0,
                        //                             color: '#00FFFF'
                        //                         }, {
                        //                             offset: 1,
                        //                             color: '#00BFFF'
                        //                         }]),
                        //                     },
                        //                     emphasis: {
                        //                         opacity: 1,
                        //                         color: '#1E90FF'
                        //                     }
                        //                 }
                        //             }
                        //         ],
                        //     }]
                        // };
                        // addUser.setOption(optionUser4)
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .user{
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: space-around;
        .data{
            width: 100px;
            height: 60px;
            line-height: 50px;
            margin-top: 35px;
            text-align: center;
            border: 2px solid #00BFFF;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            cursor: pointer;
            span{
                width: 100%;
                height: 30px;
                line-height: 28px;
            }
        }
    }
</style>