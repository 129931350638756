<template>
    <div class="user" ref="curev"></div>
</template>

<script>
    import * as echarts from 'echarts'
    import { curev} from '@/libs/apiBIgScreen'
    export default {
        name: 'userCurve',
        mounted(){
            this.$nextTick(()=>{
                setTimeout(()=>{
                    this.init()
                },500)
            })
            this.time = setInterval(()=>{
                this.init()
                clearInterval(this.tiemSite)
                this.tiemSite = null
            },30000)
        },
        beforeDestroy(){
            clearInterval(this.time)
            this.time = null
        },
        data(){
            return {
                show: false,
                info: {}
            }
        },
        methods: {
            init(){
                curev({
                    dayNum: 15
                }).then((res)=>{
                    let data = res.data
                    if(data.code === 0){
                        let xdata = []
                        let sdata = []
                        res.data.data.forEach((item)=>{
                            xdata.push(item.time.substr(-2)+'日')
                            sdata.push(item.count)
                        })
                        if (this.$refs.curev == null) {
                            return
                        }
                        let curev = echarts.getInstanceByDom(this.$refs.curev);
                        if (curev == null) { // 如果不存在，就进行初始化。
                            curev = echarts.init(this.$refs.curev)
                        }
                        window.addEventListener("resize", () => {
                            curev.resize()
                        })
                        curev.on('click', (params) => {
                            this.show = true
                            this.info = Object.assign({},{modalType:'user'},params)
                        })
                        let option = {
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    lineStyle: {
                                        color: 'rgb(126,199,255)'
                                    }
                                },
                                formatter: (params) => {
                                    return `
                                    ${params[0].name}  <br/>
                                    ${params[0].marker}
                                    ${params[0].seriesName} :
                                    ${params[0].value.toLocaleString()}个`
                                },
                            },
                            legend: {
                                show: true,
                                bottom: '15%',
                                itemWidth: 30, // 图例标记的图形宽度。
                                itemGap: 20, // 图例每项之间的间隔。
                                itemHeight: 10, //  图例标记的图形高度。
                                textStyle: {
                                    color: '#fff',
                                    fontSize: 14,
                                    padding: [0, 8, 0, 8]
                                }
                            },
                            grid: {
                                top: '0%',
                                left: '4%',
                                right: '4%',
                                bottom: '23%'
                            },
                            xAxis: [
                                {
                                    type: 'category',
                                    boundaryGap: false,
                                    axisLine: {
                                        //坐标轴轴线相关设置。数学上的x轴
                                        show: true,
                                        lineStyle: {
                                            color: 'rgb(41,188,245)'
                                        }
                                    },
                                    axisLabel: {
                                        interval: 0,
                                        textStyle: {
                                            color: '#FFFFFF',
                                            fontSize: 10
                                        },
                                    },
                                    splitLine: {
                                        show: false,
                                        lineStyle: {
                                            color: '#233653'
                                        }
                                    },
                                    axisTick: {
                                        show: false,
                                    },
                                    data: xdata
                                }
                            ],
                            yAxis: [
                                {
                                    minInterval: 1,
                                    type: 'value',
                                    splitLine: {
                                        show: false,
                                        lineStyle: {
                                            color: '#1160a0',
                                            type: 'dashed'
                                        }
                                    },
                                    axisLine: {
                                        show: false,
                                        lineStyle: {
                                            color: '#008de7'
                                        }
                                    },
                                    axisLabel: {
                                        show: false,
                                        textStyle: {
                                            color: '#fff',
                                            fontSize: 14
                                        }
                                    },
                                    axisTick: {
                                        show: false
                                    }
                                }
                            ],
                            series: [
                                {
                                    type: 'line',
                                    smooth: true,
                                    name: '用户增量', // 图例对应类别
                                    lineStyle: {
                                        normal: {
                                            width: 3,
                                            color: '#ffba00', // 线条颜色
                                        },
                                    },
                                    itemStyle: {
                                        normal: {
                                            color: '#fff600',//拐点颜色
                                            label: {
                                                show: false, //开启显示
                                                color: '#fff',
                                                position: 'top' //在上方显示
                                            }
                                        }
                                    },
                                    symbolSize: 8,   //设定实心点的大小
                                    areaStyle: { // 区域颜色
                                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                        {
                                            offset: 0,
                                            color: 'rgba(255,186,0,.4)'
                                        },
                                        {
                                            offset: 0.6,
                                            color: 'rgba(255,186,0,.1)'
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(255,186,0,.3)'
                                        }
                                        ])
                                    },
                                    data: sdata// 纵坐标数据
                                }
                            ]
                        }
                        curev.setOption(option)
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .user{
        width: 100%;
        height: 100px;
    }
</style>