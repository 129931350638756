<template>
    <div>
        <div ref="chargestate" style="width: 100%;height: 220px;"></div>
        <Edialog :show="show" :info="info" @closeModal="closeModal"/>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    import { UseOfStateDetails} from '@/libs/apiBIgScreen'
    import Edialog from '../../modal'
    export default {
        components: {
            Edialog
        },
        name: 'index',
        mounted(){
            this.$nextTick(()=>{
                setTimeout(()=>{
                    this.init()
                },500)
            })
            this.time = setInterval(()=>{
                this.init()
                clearInterval(this.tiemSite)
                this.tiemSite = null
            },30000)
        },
        beforeDestroy(){
            clearInterval(this.time)
            this.time = null
        },
        data(){
            return {
                time: null,
                show: false,
                info: {}
            }
        },
        methods: {
            closeModal(){
               this.show = false 
            },
            init(){
                UseOfStateDetails().then((res)=>{ //电桩状态
                    let data = res.data
                    if(data.code === 0){
                        if (this.$refs.chargestate == null) {
                            return
                        }
                        let myChart = echarts.getInstanceByDom(this.$refs.chargestate);
                        if (myChart == null) { // 如果不存在，就进行初始化。
                            myChart = echarts.init(this.$refs.chargestate)
                        }
                        window.addEventListener("resize", () => {
                            myChart.resize()
                        })
                        myChart.on('click', (params) => {
                            this.show = true
                            this.info = Object.assign({},{showType:'index',eleType: 'chargestate'},params)
                        })
                        let pieColors = ['#91cc75', '#5470c6', '#fac858', '#ee6666']
                        let richColor = {}
                        pieColors.forEach((item, idx) => {
                            richColor[`color${idx}`] = {
                                fontSize: 12,
                                color: item
                            }
                        })

                        let chartData = [
                            { value: data.data.idle, name: '空闲' },
                            { value: data.data.occupied, name: '使用' },
                            { value: data.data.offLine, name: '离线' },
                            { value: data.data.fault, name: '故障' }
                        ]

                        let option = {
                            color: pieColors,
                            series: [
                                {
                                    name: '电枪状态',
                                    type: 'pie',
                                    radius: ['50%', '80%'],
                                    center:['50%','56%'],
                                    avoidLabelOverlap: true,
                                    label: {
                                        formatter: params => {
                                            return `{color${params.dataIndex}|${params.name}(${params.value})}`
                                        },
                                        rich: richColor
                                    },
                                    labelLine: {
                                        lineStyle: {
                                            width: 3
                                        }
                                    },
                                    data: chartData
                                },
                                {
                                    name: '总电枪',
                                    type: 'pie',
                                    radius: ['10%', '20%'],
                                    itemStyle: { // 防止鼠标悬浮到标签时出现放大的点
                                        color: 'transparent'
                                    },
                                    label: {
                                        position: 'inside',
                                        formatter: `{data|{c}}\n{serie|{a}}`,
                                        rich: {
                                        data: {
                                            fontWeight: 'bold',
                                            fontSize: 16,
                                            color: `#fff`,
                                            lineHeight: 16,
                                            textBorderColor: `transparent`,
                                            textBorderWidth: 0
                                        },
                                        serie: {
                                            fontSize: 16,
                                            color: `#fff`,
                                            lineHeight: 16,
                                            textBorderColor: `transparent`,
                                            textBorderWidth: 0
                                        }
                                        }
                                    },
                                    labelLine: {
                                        show: false
                                    },
                                    data: [ // 计算表格数据value总和
                                        data.data.total
                                    ]
                                }
                            ]
                        }
                        myChart.setOption(option)
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>

</style>