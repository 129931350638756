<template>
    <div @click="toGo($event)" style="height: 85%; overflow-y: hidden;">
        <vue-seamless-scroll :data="listData" :class-option="classOption" class="warp">
            <ul>
                <li v-for="(item, index) in listData" :key="index">
                    <span class="index">{{index+1}}</span>
                    <div class="content">
                        <div class="left" style="width: 100%">
                        <span class="title">{{item.siteName}}</span>
                        <div :style="{width:max(item.totalElectricQuantity/10000,index)}" class="click" :id="item.siteCode">
                            <Progress class="progress" hide-info :percent="100" :stroke-width="10" :stroke-color="['#108ee9', '#00bfff']"/>
                            <span class="right" >{{ item.totalElectricQuantity/10000 }}度</span>
                        </div>
                        </div>
                    </div>
                </li>
            </ul>
            <Edialog :show="show" :info="info" @closeModal="closeModal"/>
        </vue-seamless-scroll>
    </div>
</template>

<script>
    import vueSeamlessScroll  from 'vue-seamless-scroll'
    import { ElectricQuantity} from '@/libs/apiBIgScreen'
    import Edialog from '../../modal'
    export default {
        name:'scroll',
        components: {
            vueSeamlessScroll,
            Edialog
        },
        mounted(){
            this.$nextTick(()=>{
                setTimeout(()=>{
                    this.init()
                },500)
            })
            this.time = setInterval(()=>{
                this.init()
            },30000)
        },
        beforeDestroy(){
            clearInterval(this.time)
            this.time = null
        },
        data () {
            return {
                time: null,
                listData: [],
                classOption: {
                    step: 0.5
                },
                num: 0,
                show: false,
                info: {}
            }
        },
        methods: {
            closeModal(){
               this.show = false
            },
            init(){
                ElectricQuantity({ //充电站电量排行
                    topNum: 10,
                    OrderBy: 'TotalElectricQuantity',
                    Sort: 'descending'
                }).then((res)=>{
                    let data = res.data
                    if(data.code === 0){
                        this.listData = data.data
                    }
                })
            },
            toGo (e) {
                let el = e.target.parentNode.parentNode.parentNode.parentNode
                if (el && el.className === 'click') {
                    let siteCode = el.id
                    this.show = true
                    this.info = Object.assign({},{showType:'expand',data:{siteCode}})
                }
            },
            max(ele,index) {
                if(index == 0){
                    this.num = ele/100
                    return "100%"
                }else{
                    return (ele/this.num).toFixed(2) + "%"
                }
            }
        }
    }
</script>

<style lang="less" scoped>
.warp {
    height: 85%;
    width: 100%;
    overflow: hidden;
    ul {
      padding: 0;
      margin: 0;
      li{
        width: 100%;
        padding: 7px 0;
        list-style: none;
        position: relative;
        clear: both;
        .index{
            display: block;
            text-align: center;
            float: left;
        }
      }
      .content{
        width: 80%;
        margin-left: 20px;
        .left{
            .title{
                font-size: 12px;
                text-align: center;
            }
            .click{
                position: relative;
                z-index: 1000;
                .progress{
                    cursor: pointer;
                }
            }
        }
        .right{
            position: absolute;
            right: -80px;
            bottom: -2px;
        }
      }
    }
  }
</style>
