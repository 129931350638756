<template>
    <div>
        <div ref="todayele" style="width: 100%;height: 228px;"></div>
        <Edialog :show="show" :info="info" @closeModal="closeModal"/>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    import { ChargingCapacityStat} from '@/libs/apiBIgScreen'
    import Edialog from '../../modal'
    export default {
        components: {
            Edialog
        },
        name: 'index',
        mounted(){
            this.$nextTick(()=>{
                setTimeout(()=>{
                    this.init()
                },500)
            })
            this.time = setInterval(()=>{
                this.init()
                clearInterval(this.tiemSite)
                this.tiemSite = null
            },30000)
        },
        beforeDestroy(){
            clearInterval(this.time)
            this.time = null
        },
        data(){
            return {
                show: false,
                info: {}
            }
        },
        methods: {
            closeModal(){
               this.show = false
            },
            init(){
                ChargingCapacityStat({//今日充电量统计
                    type: 0
                }).then((res)=>{
                    let data = res.data
                    if(data.code === 0){
                        if (this.$refs.todayele == null) {
                            return
                        }
                        let myChart = echarts.getInstanceByDom(this.$refs.todayele)
                        if (myChart == null) { // 如果不存在，就进行初始化。
                            myChart = echarts.init(this.$refs.todayele)
                        }
                        window.addEventListener("resize", () => {
                            myChart.resize()
                        })
                        myChart.on('click', (params) => {
                            this.show = true
                            this.info = Object.assign({},{showType:'index',eleType: 'day'},params)
                        })
                        // x轴数据
                        let xAxisData = []
                        data.data.forEach((item)=>{
                            xAxisData.push(item.time.substr(-2)+'点')
                        })
                        // series中数据
                        let seriesData = []
                        data.data.forEach((item)=>{
                            seriesData.push({value:item.totalElectricQuantity/10000,time:item.time})
                        })
                        let option = {
                            // color: ["#00ffa2"],
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    lineStyle: {
                                        color: 'rgb(126,199,255)'
                                    }
                                },
                                formatter: (params) => {
                                    return `
                                    ${params[0].name}  <br/>
                                    ${params[0].marker}
                                    ${params[0].seriesName} :
                                    ${params[0].value == 0 ? "-" : Number(params[0].value).toLocaleString()}度`;
                                },
                            },
                            legend: {
                                show: true,
                                top: '1%',
                                itemWidth: 30, // 图例标记的图形宽度。
                                itemGap: 20, // 图例每项之间的间隔。
                                itemHeight: 10, //  图例标记的图形高度。
                                textStyle: {
                                    color: '#fff',
                                    fontSize: 14,
                                    padding: [0, 8, 0, 8]
                                }
                            },
                            grid: {
                                top: '20%',
                                left: '3%',
                                right: '5%',
                                bottom: '10%'
                            },
                            xAxis: [
                                {
                                    type: 'category',
                                    boundaryGap: false,
                                    axisLine: {
                                        //坐标轴轴线相关设置。数学上的x轴
                                        show: true,
                                        lineStyle: {
                                            color: 'rgb(41,188,245)'
                                        }
                                    },
                                    axisLabel: {
                                        interval: 0,
                                        rotate: -20,   //值>0向右倾斜，值<0则向左倾斜
                                        textStyle: {
                                            color: '#FFFFFF',
                                            fontSize: 10
                                        },
                                    },
                                    splitLine: {
                                        show: false,
                                        lineStyle: {
                                            color: '#233653'
                                        }
                                    },
                                    axisTick: {
                                        show: false,
                                    },
                                    data: xAxisData
                                }
                            ],
                            yAxis: [
                                {
                                    nameTextStyle: {
                                        color: "#fff",
                                        fontSize: 12,
                                        padding: [0, 60, 0, 0]
                                    },
                                    minInterval: 1,
                                    type: 'value',
                                    splitLine: {
                                        show: true,
                                        lineStyle: {
                                            color: '#1160a0',
                                            type: 'dashed'
                                        },
                                    },
                                    axisLine: {
                                        show: false,
                                        lineStyle: {
                                            color: '#008de7',
                                        },
                                    },
                                    axisLabel: {
                                        show: false,
                                        textStyle: {
                                            color: '#fff',
                                            fontSize: 14
                                        }
                                    },
                                    axisTick: {
                                        show: false
                                    }
                                }
                            ],
                            series: [
                                {
                                    name: "时电量",
                                    type: "line",
                                    symbol: "circle",
                                    smooth: false,
                                    lineStyle: {
                                        normal: {
                                            width: 3,
                                            color: '#00ffa2', // 线条颜色
                                        }
                                    },
                                    itemStyle: {
                                        normal: {
                                            color: '#00ffa2',//拐点颜色
                                            label: {
                                                show: false, //开启显示
                                                color: '#fff',
                                                position: 'top', //在上方显示
                                            }
                                        }
                                    },
                                    symbolSize: 8,   //设定实心点的大小
                                    areaStyle: {
                                        normal: {
                                            color: new echarts.graphic.LinearGradient(
                                                0,
                                                0,
                                                0,
                                                1,
                                                [
                                                    {
                                                        offset: 0,
                                                        color: "rgba(0,255,162,.4)",
                                                    },
                                                    {
                                                        offset: 1,
                                                        color: "rgba(0,255,162, .1)",
                                                    }
                                                ],
                                                false
                                            ),
                                            shadowColor: "rgba(0,255,162, .3)",
                                            shadowBlur: 2
                                        }
                                    },
                                    data: seriesData
                                }
                            ]
                        };
                        myChart.setOption(option)
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>

</style>