import { render, staticRenderFns } from "./scroll.vue?vue&type=template&id=6fefb0c3&scoped=true&"
import script from "./scroll.vue?vue&type=script&lang=js&"
export * from "./scroll.vue?vue&type=script&lang=js&"
import style0 from "./scroll.vue?vue&type=style&index=0&id=6fefb0c3&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fefb0c3",
  null
  
)

export default component.exports